/* eslint-disable prettier/prettier */

import { parse } from "csv-parse";
import { FileUploadErrors, FileResult } from "../../interfaces";

interface FieldValidators {
  [key: string]: (field: string) => boolean;
}

enum ParserStep {
  REDABLE = "readable",
  ERROR = "error",
  END = "end"
}

export const processCsvFile = (
  file: File,
  fieldValidators: FieldValidators,
  onCompleted: (result: FileResult<string>) => void
) => {
  const reader = new FileReader();

  reader.onload = function (e) {
    if (!(e?.target?.result))
      onCompleted({ errorParse: true });

    const fileContent = e.target.result.toString();
    const records = [];
    const errorLines: FileUploadErrors[] = [];
    const successData: string[] = [];


    const parser = parse(fileContent, {
      delimiter: ",",
      columns: false,
      skip_empty_lines: true
    });

    parser.on(ParserStep.REDABLE, () => {
      let record;
      while ((record = parser.read()) !== null) {
        records.push(record);
      }
    });

    parser.on(ParserStep.ERROR, () => {
      onCompleted({ errorParse: true });
    });

    parser.on(ParserStep.END, () => {
      records.forEach((row, index) => {

        Object.entries(row).forEach(([fieldName, fieldValue]) => {
          if (!fieldName && !fieldValue) return;
          const validateField = fieldValidators[fieldName];
          (validateField && !validateField(fieldValue as string)) ?
            errorLines.push({ numberLine: index + 1, valuesLine: String(fieldValue) }) :
            successData.push(fieldValue as string);

        });

        onCompleted({ data: successData, formatErrorLines: errorLines, errorParse: false });
      });
    });
  }

  reader.readAsText(file);
}