import { format } from "date-fns";

/* eslint-disable prettier/prettier */
export const formatCurrency = ({ amount, currency = "HNL" }) => {
  return new Intl.NumberFormat("es-HN", { style: "currency", currency }).format(
    amount
  );
};

export const currencyToSimbol = (currency: string): string => {
  switch (currency) {
    case "HNL":
      return "L";
    case "USD":
      return "$";
    default:
      return currency;
  }
};

export const formatLocalDate = (dateString: string, formatString?: string) => {
  const date = new Date(dateString);
  const localDate = new Date(
    date.valueOf() + date.getTimezoneOffset() * 60 * 1000
  );
  return format(localDate, formatString || "yyyy-MM-dd");
};

export const getFirstDecimal = (num?: number, fixed: number = 2): number | undefined => {
  if (num === undefined) return undefined;
  const [integerPart, decimalPart] = num.toFixed(fixed).split('.');
  return parseFloat(`${integerPart}.${decimalPart[0]}`);
};