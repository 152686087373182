/* eslint-disable prettier/prettier */
export interface FileUploadErrors {
    numberLine: number;
    valuesLine: string;
}

export enum FileUploadStatus {
    PENDING,
    IN_PROGRESS,
    SAVED,
    ERROR,
    NOT_FOUND
}

export interface FileUploadProgress {
    value: string;
    status: FileUploadStatus;
}

export interface FileResult<T> {
    data?: T[],
    formatErrorLines?: FileUploadErrors[];
    errorParse: boolean;
    progress?: FileUploadProgress[];
}
